import React, { Component } from 'react';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <div className="">
         
         <footer>
          <div className="container">
            <div className="md_container">
              <img className="footer_logo" src="img/logo.png" alt="" />
              <hr />
              <div className="text-center">
                <p className="copy_text">&copy; 2022 Millionsmatic All Rights Reserved. </p>
              </div>
            </div>
          </div>
        </footer>
          
        <div className="overlay">
          <div className="overlayDoor"></div>
          <div className="overlayContent">
            <img src="img/logo.png" alt="" />
            <p>Loading.....</p>
          </div>
        </div>

        
        </div>
      );
    }
  }

  export default Footer;