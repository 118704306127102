import React, { Component } from 'react';

class Header extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <header className="pt-4 pb-4">
        <div className="container">
          <div className="md_container">
            <div className="d-flex text-center">
              <div className="flex-fill">
                <a href="/">
                  <img className="logo" src="img/logo.png" alt="" />
                </a>
              </div>
              
            </div>
            </div>
        </div>
       </header>
      );
    }
  }

  export default Header;