import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MillionsMatic from "../../contracts/MillionsMatic.json";
import getWeb3 from "../../getWeb3";
const defaultAmountArr = ['100','200','500','1000','2000'];

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      web3: null,
      accounts: null,
      contract: null,
      contractAddress: null,
      sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
      userInfo:{},
      TotalBonus:0,
      totalWithdrawn:0,
      totalDeposits:0,
      totalInvested:0,
      totalUsers:0,
      networkMain:false,
      YourAddress: '-',
      YourAddressfull: null,
      amountBNB:null,
      user_referer:'-----------',
      balance:null,
      adminFee:null,
      adminFee_percentage:5,
      reinvest_percentage:0,
      withdrwal_percentage:0,
      //GetDownlineIncomeByUserId:0,
      //GetUplineIncomeByUserId:0,
      communityLevels:[],
      sponsorList:[],
      poolDepositTotal:0,
      poolDeposit:0,
      poolQualifierCount:0,
      GetPoolIncome:0,
      GetPoolIncomeTwo:0,
      _userUplineIncome:0,
      _userDownlineIncome:0,
      poolDepositTotalTwo:0,
      poolDepositTwo:0,
      poolQualifierCountTwo:0,
      getUserDividends: 0,
      availableBalance:0,
    }
  }

  componentDidMount = async () => {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      setInterval(async()=>{
        const accounts = await web3.eth.getAccounts();
       
        if(this.state.accounts !== accounts[0]){
        // Get the contract instance.
        const networkId = await web3.eth.net.getId();
        //alert(networkId);
        //if(networkId !== 56) throw "Please connect Mainnet"; 
        const deployedNetwork = MillionsMatic.networks;
        const instance = new web3.eth.Contract(
          MillionsMatic.abi,
          deployedNetwork && MillionsMatic.address,
        );   
        // let accounts = '0x78DBc97d118D6dfd8912b01bb8Ae8D7eccD8E085'
        this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:MillionsMatic.address}, this.fetchData);
        }
     },1000);

    } catch (error) {
      // Catch any errors for any of the above operations.
      // alert(
      //   error
      // );
      this.setState({networkMain:false});
      console.error(error);
    }
  };

  fetchData = async() => {
    const { accounts, contract } = this.state;

    //console.log(this.state.contractAddress);

    let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
    let YourAddressfull = accounts;
    let userInfo = await contract.methods.users(accounts).call();    
    userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
    userInfo.amount = parseFloat(userInfo.amount).toFixed(5);
    userInfo.referrerBonus = this.state.web3.utils.fromWei(userInfo.referrerBonus, 'ether');
    userInfo.referrerBonus = parseFloat(userInfo.referrerBonus).toFixed(5);
    userInfo.totalWithdrawn = this.state.web3.utils.fromWei(userInfo.totalWithdrawn, 'ether');
    userInfo.totalWithdrawn = parseFloat(userInfo.totalWithdrawn).toFixed(5);
    let user_referer = '---------';
    if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
      user_referer = userInfo.referrer;
      user_referer = user_referer.slice(0, 6) + '...' + user_referer.slice(user_referer.length-5, user_referer.length);
    }

    // let GetPoolIncome = await contract.methods.GetPoolIncome(accounts).call();
    // GetPoolIncome = this.state.web3.utils.fromWei(GetPoolIncome[0], 'ether');
    // GetPoolIncome = parseFloat(GetPoolIncome).toFixed(5);

   


    let getUserDividends = await contract.methods.getUserDividends(accounts).call();
    let TotalBonus = await contract.methods.TotalBonus(accounts).call();
    let availableBalance = parseFloat(getUserDividends) + parseFloat(TotalBonus);
    //console.log(availableBalance);
    let adminFee = availableBalance*this.state.adminFee_percentage/100;
    availableBalance = parseFloat(availableBalance) - parseFloat(adminFee); 

    
    
   
    getUserDividends = this.state.web3.utils.fromWei(getUserDividends, 'ether');
    getUserDividends = parseFloat(getUserDividends).toFixed(5);


    // let GetPoolIncomeTwo = await contract.methods.GetPoolIncomeTwo(accounts).call();
    // GetPoolIncomeTwo = this.state.web3.utils.fromWei(GetPoolIncomeTwo[0], 'ether');
    // GetPoolIncomeTwo = parseFloat(GetPoolIncomeTwo).toFixed(5);
    
    TotalBonus = this.state.web3.utils.fromWei(TotalBonus, 'ether');
    TotalBonus = parseFloat(TotalBonus).toFixed(5);
    let totalWithdrawn = await contract.methods.totalWithdrawn().call();
    totalWithdrawn = this.state.web3.utils.fromWei(totalWithdrawn, 'ether');
    totalWithdrawn = parseFloat(totalWithdrawn).toFixed(5);
    let totalDeposits = await contract.methods.totalDeposits().call();
    totalDeposits = parseFloat(totalDeposits).toFixed(5);
    let totalInvested = await contract.methods.totalInvested().call();
    totalInvested = this.state.web3.utils.fromWei(totalInvested, 'ether');
    totalInvested = parseFloat(totalInvested).toFixed(5);
    let totalUsers = await contract.methods.totalUsers().call();

    let poolDepositTotal = await contract.methods.poolDepositTotal().call();
    poolDepositTotal = this.state.web3.utils.fromWei(poolDepositTotal, 'ether');
    poolDepositTotal = parseFloat(poolDepositTotal).toFixed(5);

    let poolDepositTotalTwo = await contract.methods.poolDepositTotalTwo().call();
    poolDepositTotalTwo = this.state.web3.utils.fromWei(poolDepositTotalTwo, 'ether');
    poolDepositTotalTwo = parseFloat(poolDepositTotalTwo).toFixed(5);

    let poolDeposit = await contract.methods.poolDeposit().call();
    poolDeposit = this.state.web3.utils.fromWei(poolDeposit, 'ether');
    poolDeposit = parseFloat(poolDeposit).toFixed(5);

    let poolDepositTwo = await contract.methods.poolDepositTwo().call();
    poolDepositTwo = this.state.web3.utils.fromWei(poolDepositTwo, 'ether');
    poolDepositTwo = parseFloat(poolDepositTwo).toFixed(5);

    // let poolQualifierCount = await contract.methods.poolQualifierCount().call();
    // let poolQualifierCountTwo = await contract.methods.poolQualifierTwoCount().call();
    

    let balance = TotalBonus + getUserDividends;
    // let adminFee = balance*this.state.adminFee_percentage/100;
    // balance = balance - adminFee;


    let getEligibleWithdrawal = await contract.methods.getEligibleWithdrawal(accounts).call();
    let reinvest_percentage = getEligibleWithdrawal.reivest;
    let withdrwal_percentage = getEligibleWithdrawal.withdrwal;

    // let singleUplineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleUplineBonusTaken, 'ether');
    // let singleDownlineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleDownlineBonusTaken, 'ether');

    // let GetDownlineIncomeByUserId = await contract.methods.GetDownlineIncomeByUserId(accounts).call();
    // GetDownlineIncomeByUserId = this.state.web3.utils.fromWei(GetDownlineIncomeByUserId, 'ether');
    // GetDownlineIncomeByUserId = GetDownlineIncomeByUserId - singleDownlineBonusTaken;
    // GetDownlineIncomeByUserId = parseFloat(GetDownlineIncomeByUserId).toFixed(5);

    // let GetUplineIncomeByUserId = await contract.methods.GetUplineIncomeByUserId(accounts).call();
    // GetUplineIncomeByUserId = this.state.web3.utils.fromWei(GetUplineIncomeByUserId, 'ether');
    // GetUplineIncomeByUserId = GetUplineIncomeByUserId - singleUplineBonusTaken;
    // GetUplineIncomeByUserId = parseFloat(GetUplineIncomeByUserId).toFixed(5);

    // let _userUplineIncome = await contract.methods._userUplineIncome(accounts).call();
    // _userUplineIncome = this.state.web3.utils.fromWei(_userUplineIncome, 'ether');
    // _userUplineIncome = (_userUplineIncome-singleUplineBonusTaken);
    // _userUplineIncome = parseFloat(_userUplineIncome).toFixed(5);

    // let singleDownlineBonus = this.state.web3.utils.fromWei(userInfo.singleDownlineBonus, 'ether');
    // let _userDownlineIncome = (singleDownlineBonus-singleDownlineBonusTaken);
    // _userDownlineIncome = parseFloat(_userDownlineIncome).toFixed(5);

     
    this.setState({ 
      userInfo,
      TotalBonus,
      totalWithdrawn,
      totalDeposits,
      totalInvested,
      totalUsers,
      poolDepositTotal,
      poolDepositTotalTwo,
      poolDeposit,
      poolDepositTwo,
      poolQualifierCount:0,
      poolQualifierCountTwo:0,
      GetPoolIncome:0,
      GetPoolIncomeTwo:0,
      //networkMain:true,
      YourAddress:YourAddress,
      YourAddressfull:YourAddressfull,
      user_referer:user_referer,
      balance,
      adminFee,
      reinvest_percentage,
      withdrwal_percentage,
      getUserDividends,
      availableBalance,
      //GetUplineIncomeByUserId,
      //GetDownlineIncomeByUserId,
      // _userUplineIncome,
      // _userDownlineIncome,
      loading:false
    },async()=>{
      const { accounts, contract } = this.state;
      // let getEligibleLevelCountForUpline = await contract.methods.getEligibleLevelCountForUpline(accounts).call();
      let uplineCount = 30;//getEligibleLevelCountForUpline.uplineCount;
      let downlineCount = 20;//getEligibleLevelCountForUpline.downlineCount;
      let communityLevels = [];
      let upline_users = [];
      let downline_users = [];
      let current_user = accounts;
      let userInfo = await contract.methods.users(current_user).call();
      for(let i=1;i<=uplineCount;i++){          
        if(current_user == userInfo.singleUpline) continue;
        current_user = userInfo.singleUpline;
        let emptyAddress = /^0x0+$/.test(current_user);
        if(emptyAddress) continue;
        userInfo = await contract.methods.users(current_user).call();
        let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
        let income = (investment/100).toFixed(5);
        investment = parseFloat(investment).toFixed(5);
        upline_users.push({level:i,levelText:'Upline-'+i,type:'upline',username:current_user,investment:investment,income:income});
      }
      upline_users.sort((a, b) => b.level>a.level? 1 : -1);
      upline_users.map(function(val, index){
        communityLevels.push(val);
      });
      let currentUserInfo = await contract.methods.users(accounts).call();
      let investment = this.state.web3.utils.fromWei(currentUserInfo.amount, 'ether');
      let income = (investment/100).toFixed(5);
      income = (0).toFixed(5);
      investment = parseFloat(investment).toFixed(5);
      let main_user = {level:0,levelText:'You',type:'main_user',username:accounts,investment:investment,income:income};
      communityLevels.push(main_user);
      current_user = accounts;
      userInfo = await contract.methods.users(current_user).call();
      for(let i=1;i<=downlineCount;i++){
        if(current_user == userInfo.singleDownline) continue;
        current_user = userInfo.singleDownline;
        let emptyAddress = /^0x0+$/.test(current_user);
        if(emptyAddress) continue;
        userInfo = await contract.methods.users(current_user).call();
        let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
        let income = (investment/100).toFixed(5);
        investment = parseFloat(investment).toFixed(5);
        downline_users.push({level:i,levelText:'Downline-'+i,type:'downline',username:current_user,investment:investment,income:income});
      }
      downline_users.map(function(val, index){
        communityLevels.push(val);
      });

      let sponsorList = [];
      let count = 0;
      for(let i=0;i<10;i++){
        let referral_stage = await contract.methods.referral_stage(accounts,i).call();
        let requiredDirectbusiness = await contract.methods.requiredDirectbusiness(i).call();
        let _investment = this.state.web3.utils.fromWei(referral_stage._investment, 'ether');
        //let ref_bonuses = await contract.methods.ref_bonuses(i).call();
        let percentage_amount = this.state.web3.utils.fromWei(referral_stage._bonus, 'ether'); //(_investment*ref_bonuses/100).toFixed(5);
        _investment = parseFloat(_investment).toFixed(5);
        percentage_amount = parseFloat(percentage_amount).toFixed(5);
        let _noOfUser = referral_stage._noOfUser;
        
        if(i == 0){
          count = _noOfUser;
        }
        let status = '';
        let requireText = '';
        if(_investment<requiredDirectbusiness){
          status = 'Unqualified';
          requireText = 'require '+requiredDirectbusiness + ' investment';
        }
        sponsorList.push({requireText:requireText,status:status,level:i+1,_investment:_investment,_noOfUser:_noOfUser,percentage_amount:percentage_amount});
      }
      
      //this.setState({sponsorList});
      this.setState({communityLevels, sponsorList});
      // console.log(communityLevels);      
      // console.log(sponsorList);       
    });     
  }

  doJoinNow = async () => {
    //const weiValue = this.state.web3.utils.toWei('1', 'ether');
    //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
    const { accounts, contract } = this.state;
    let sponsor_address = this.state.sponsor_address;
    let userInfo = await contract.methods.users(accounts).call();
    if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
      sponsor_address = userInfo.referrer;
    }
    else if(!sponsor_address){
      this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
      this.setState({sponsor_addressError:true});
      return false;
    }

    if(!this.state.amountBNB){
      this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
      this.setState({amountError:true});
      return false;
    }
    
    let balance = await this.state.web3.eth.getBalance(this.state.accounts);
    let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
    let amountBNB = this.state.amountBNB;
    if(sponsor_address){
      if(balanceEthVal >= amountBNB){
        try {
          this.setState({loading:true});
          this.state.contract.events.NewDeposit((err, eventResult) => {
            console.log('eventResult',eventResult);
            if (!err) {
              let uerAddress = this.state.accounts;
              if(eventResult.returnValues[0] == uerAddress){
                this.fetchData();
                this.setState({loading:false});
                this.props.enqueueSnackbar("Joined Successfully!",{ variant: 'success' });                
              }              
            }else{
              console.log(err);
              this.setState({loading:false});
              this.props.enqueueSnackbar('Some Network Error Occurred!',{ variant: 'error' });              
            }
          });

          let weiValue = this.state.web3.utils.toWei(amountBNB, 'ether');
          let invest = await this.state.contract.methods.invest(sponsor_address).send(
            {
              from: this.state.accounts,
              value:weiValue
            }
          );
          //console.log(invest);
        }
        catch(err) {
          this.setState({loading:false});
          if (err.message.includes("User denied transaction signature")) {
            // handle the "error" as a rejection
            this.props.enqueueSnackbar(err.message,{ variant: 'error' });
          }else{
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }          
        }          
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Insufficient Balance!",{ variant: 'error' });          
      }        
    }else{
      this.setState({loading:false});
      this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
    }
  }

  doWithdrawal = async () => {
    if(this.state.TotalBonus > 0){
      this.setState({loading:true});
      this.state.contract.events.Withdrawn((err, eventResult) => {
        console.log('eventResult',eventResult);
        console.log('err',err);
        if (!err) {
          let uerAddress = this.state.accounts;
          if(eventResult.returnValues[0] == uerAddress){
            this.fetchData();
            this.setState({loading:false});
            this.props.enqueueSnackbar("Withdrawal Successfully!",{ variant: 'success' });                
          }              
        }else{
          console.log(err);
          this.setState({loading:false});
          this.props.enqueueSnackbar('Some Network Error Occurred!',{ variant: 'error' });              
        }        
      });

      let withdrawal = await this.state.contract.methods.withdrawal().send(
        { 
          from: this.state.accounts
        }
      );
      //console.log('withdrawal',withdrawal); 
    }else{
      this.props.enqueueSnackbar('Insufficient balance!',{ variant: 'error' });
    }
         
  }

  render() {
    return (
      <Frame withHeader={true} withFooter={true}>
        
        {/* <div className="modal fade" id="vide_modal1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-keyboard="false" data-backdrop="static">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <button data-toggle="modal" data-target="#vide_modal2" data-dismiss="modal" type="button" className="modal_close">
                  <img className="modal_close_img" src="/img/Close-icon.png" alt="" />
              </button>
                <div className="modal_video">
                  <video controls="controls" playsinline="playsinline" muted="muted" preload="yes" autoplay="autoplay" loop="loop">
                      <source src="video/Final-Polygon-Business.mp4" type="video/mp4" />
                  </video>
                </div>
            </div>
          </div>
        </div> */}
        {this.state.loading ? (
          <div className="loader-container">
            <span className="loader-2"> </span>
          </div>
        ) : (null)}

        
          <div className="banner_section">
            <div className="container">
              <div className="banner_container">
                <h5>Scalable Crypto Investment!</h5>
                <h1>Millionsmatic is a reliable investment platform with varied investment solutions.</h1>
                <p>The platform is designed to provide you with the opportunity to choose from multiple investment solutions driving astounding financial gains and generating higher earnings from every investment.</p>
                <a target="_blank" href={`https://polygonscan.com/address/${MillionsMatic.address}`} className="all_btn">Verified Contract  <i className="fa fa-angle-right"></i></a>
              </div>
            </div>
          </div>


          <div className="banner_btm">
            <div className="container">
              <div className="banner_btm_box">
                <div className="md_container">
                  <div className="row g-2">
                    <div className="col-md-4 col-sm-6 col-lg-4 col-6">
                      <div className="box_inner bg1">
                        <h4>Total Community</h4>
                        <h5>{this.state.totalUsers}</h5>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-lg-4 col-6">
                      <div className="box_inner bg2">
                        <h4>Total Invested</h4>
                        <h5>{this.state.totalInvested}</h5>
                      </div>
                    </div>
                    {/* <div className="col-md-4 col-sm-6 col-lg-4 col-6">
                      <div className="box_inner bg3">
                        <h4>Total Pool 1</h4>
                        <h5>{this.state.poolDepositTotal}</h5>
                      </div>
                    </div> */}
                    <div className="col-md-4 col-sm-6 col-lg-4 col-6">
                      <div className="box_inner bg4">
                        <h4>Total Withdrawal</h4>
                        <h5>{this.state.totalWithdrawn}</h5>
                      </div>
                    </div>
                  
                    {/* <div className="col-md-4 col-sm-6 col-lg-4 col-6">
                      <div className="box_inner bg1">
                        <h4>Daily Pool 1</h4>
                        <h5>{this.state.poolDeposit}</h5>
                      </div>
                    </div> */}
                    {/* <div className="col-md-4 col-sm-6 col-lg-4 col-6">
                      <div className="box_inner bg2">
                        <h4>Total Pool 2</h4>
                        <h5>{this.state.poolDepositTotalTwo}</h5>
                      </div>
                    </div> */}
                    {/* <div className="col-md-4 col-sm-6 col-lg-4 col-6">
                      <div className="box_inner bg3">
                        <h4>Daily Pool 2</h4>
                        <h5>{this.state.poolDepositTotalTwo}</h5>
                      </div>
                    </div> */}
                    {/* <div className="col-md-4 col-sm-6 col-lg-4 col-6">
                      <div className="box_inner bg4">
                        <h4>Pool Qualifiers 1</h4>
                        <h5>{this.state.poolQualifierCount}</h5>
                      </div>
                    </div> */}
                    {/* <div className="col-md-4 col-sm-6 col-lg-4 col-6">
                      <div className="box_inner bg4">
                        <h4>Pool Qualifiers 2</h4>
                        <h5>{this.state.poolQualifierCountTwo}</h5>
                      </div>
                    </div> */}
                  </div>

                  <br />
                  <div className="">
                    <div className="row g-5">
                      <div className="col-md-6 col-lg-6">
                        <div className="no_box">
                          <span>1</span>
                          <h5>Decentralized investment</h5>
                          <p>An emerging decentralized investment platform based on blockchain technology that eliminates centralized financing and empowers decentralization.</p>
                        </div>
                        <div className="no_box">
                          <span>2</span>
                          <h5>Join the Community</h5>
                          <p>The platform builds connections leading to building the confidence of millions of crypto investors. Be a part of the community and share your experience.</p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6">
                        <div className="no_box">
                          <span>3</span>
                          <h5>Diversified Portfolio</h5>
                          <p>Invest your digital money through diversified investment strategies, offering diversified exposure to a market offering a scalable investment ecosystem. </p>
                        </div>
                        <div className="no_box">
                          <span>4</span>
                          <h5>Passive Earnings</h5>
                          <p>Passive earnings are ensured from incomes generated from multiple investment plans best suited for meeting both long-term and short-term investment goals.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="section_p_t section_p_b bgimg_a">
            <div className="container">
              <div className="md_container">
                <div className="row g-5">
                  <div className="col-md-6 col-sm-6 col-lg-6 middle_text">
                    <div className="all_heading">
                      <h2>Deposits and Withdrawals in one place!</h2>
                    </div>
                    <p>Manage your deposits and withdrawals and construct a diversified portfolio with the aim to maximize returns.</p>
                  </div>
                  <div className="col-md-6 col-sm-6 col-lg-6">
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-lg-6 col-6">
                        <div className="bonus_box">
                          <h2>{this.state.userInfo.amount} MATIC</h2>
                          <p>Total Deposit</p>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 col-6">
                        <div className="bonus_box">
                          <h2>{this.state.userInfo.totalWithdrawn} MATIC</h2>
                          <p>Total Withdrawn</p>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 col-6">
                        <div className="bonus_box">
                          <h2>{this.state.user_referer}</h2>
                          <p>Referred By</p>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 col-6">
                        <div className="bonus_box">
                          <h2>{this.state.getUserDividends} MATIC</h2>
                          <p>Roi Income</p>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 col-6">
                        <div className="bonus_box">
                          <h2>{this.state.userInfo.referrerBonus} MATIC</h2>
                          <p>Referrer Income</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="pt-3 pb-5 bgimg_a">
            <div className="container">
              <div className="md_container">
                <div className="all_heading heading_middle mb-5">
                  <h2>Join Now</h2>
                </div>
                  <div className="sm_container">
                    <div className="form-floating">
                      {(this.state.userInfo.checkpoint != undefined && this.state.userInfo.checkpoint > 0) ?(
                        <input className="form-control" type="text" placeholder="Sponsor Address"
                          readOnly
                          value={this.state.user_referer}
                          onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                        />
                      ):(
                        <input className="form-control" type="text" placeholder="Sponsor Addresss"
                          value={this.state.sponsor_address}
                          onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                        />
                      )}
                      <label>Sponsor Address</label>
                    </div>
                    <ul className="trx_btn mb_20">
                      {defaultAmountArr.map((amount,key) => {return(
                        <li key={key}>
                          <button className={this.state.amountBNB==amount?'active':'inactive'}
                            onClick={e => this.setState({amountBNB:amount})}>
                            {amount+' MATIC'}
                          </button>
                        </li>
                        )                              
                      })}
                    </ul>
                    <div className="text-center mb-5">
                      <button onClick={this.doJoinNow} className="all_btn big_btn">Join Now</button>
                    </div>
                  </div>

                  <div className="all_heading heading_middle mb-5">
                    <h2>Withdraw</h2>
                  </div>
                  <div className="sm_container">
                    <div className="form-group mb-3 form-floating">
                      <input className="form-control" type="text" readOnly value={this.state.availableBalance/1e18} />
                      <label>Available MATIC</label>
                    </div>

                    <div className="form-group mb-3 form-floating">
                      <input className="form-control" type="text" readOnly 
                        value={(parseFloat(this.state.reinvest_percentage*this.state.availableBalance/100)/1e18).toFixed(5)}                
                      />
                      <label>Reinvestment Amount (MATIC)</label>
                    </div>

                    <div className="form-group mb-3 form-floating">
                      <input className="form-control" type="text" readOnly
                        value={(parseFloat(this.state.withdrwal_percentage*this.state.availableBalance/100)/1e18).toFixed(5)}                
                      />
                      <label>Withdrawable Amount (MATIC)</label>
                    </div>

                <p className="mb-0">Reinvestment percentage: <strong>{this.state.reinvest_percentage}%</strong></p>
                <p className="">Withdrawable percentage: <strong>{this.state.withdrwal_percentage}% </strong></p>
              
                <div className="text-center">
                  <button onClick={this.doWithdrawal} className="all_btn big_btn">Submit</button>
                </div>
             
              </div>
              </div>
            </div>
          </div>
       
          <div className="pt-3 pb-5 bgimg_a">
            <div className="container">
              <div className="dark_box">
                  <div className="all_heading heading_middle mb-5">
                    <h2>Community Status</h2>
                  </div>
                  <div className="teble-responsive table-fixed">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Level</th>
                          <th>User ID</th>
                          <th>Investment</th>
                          {/* <th className="col-3">Income</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.communityLevels.length ? (
                          this.state.communityLevels.length>0 ? (
                            this.state.communityLevels.map(function(val, index){
                              let class_name = 'lebel_'+val.level;
                              if(val.level == 0){
                                class_name = 'current_user';
                              }
                              return (
                                <tr key={`cl${index}`} className={class_name}>
                                  <td>{val.levelText}</td>
                                  <td>{val.username}</td>
                                  <td>{val.investment} MATIC</td>
                                  {/* <td className="col-3">{val.income} MATIC</td> */}
                                </tr>
                              )
                            })
                          ):(null)
                        ) : (
                          <tr>
                            <td colSpan="4" className="text-center">No Data Available!</td>
                          </tr>
                        )}              
                      </tbody>
                    </table>
                  </div> 
              </div>
            </div>
          </div>

          <div className="pt-3 pb-5 bgimg_a">
            <div className="container">
              <div className="dark_box">
                  <div className="all_heading heading_middle mb-5">
                    <h2>My Sponsor List</h2>
                  </div>
                  <div className="teble-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th> Level </th>
                          <th> Count </th>
                          <th> Invest Amount </th>
                          <th> Amount </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.sponsorList.length ? (
                          this.state.sponsorList.length>0 ? (
                            this.state.sponsorList.map(function(val, index){
                              let class_name = 'lebel_'+val.level;
                              if(val.level == 0){
                                class_name = 'current_user';
                              }
                              return (
                                <tr key={`sl${index}`} className={class_name}>
                                  <td> Level-{val.level} <span>{val.requireText?(`(${val.requireText})`):(null)}</span></td>
                                  <td>{val._noOfUser}</td>
                                  <td>{val._investment} MATIC</td>
                                  <td>{val.percentage_amount} MATIC {val.status?(`(${val.status})`):(null)}</td>
                                </tr>
                              )
                            })
                          ):(null)
                        ) : (
                          <tr>
                            <td colSpan="4" className="text-center">No Data Available!</td>
                          </tr>
                        )}
                      </tbody>
                    </table>    
                  </div>        
              </div>
            </div>
          </div>
        
          <div className="pb-5 bgimg_a">
            <div className="container">
              <div className="md_container">
                <div className="row g-3 bg_no_box">
                  <div className="col-md-6 col-lg-6">
                    <div className="no_box bg1">
                      <span>1</span>
                      <h5>End-to-end Investment</h5>
                      <p>The platform is developed to minimize losses through an investment distribution system that works between various marketplaces and other metrics.</p>
                    </div>
                    <div className="no_box bg2">
                      <span>2</span>
                      <h5>Safe and Secure</h5>
                      <p>Investments made on Millionsmatic are protected using multi-layer security technology that protects your transactional data and the crypto invested.</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="no_box bg3">
                      <span>3</span>
                      <h5>Simplified Solutions</h5>
                      <p>Crypto investments can be confusing. Millionsmatic has simplified this by offering products crafted by successful traders and experts to help you start.</p>
                    </div>
                    <div className="no_box bg4">
                      <span>4</span>
                      <h5>Reliable Network</h5>
                      <p>Powered by blockchain, Millionsmatic is a reliable and decentralized network structure integrated with the improved P2P (peer-2-peer) network.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div className="footer_top_box">
          <div className="container">
            <div className="footer_top">
              <div className="all_heading heading_middle mb-3">
                <h2>Earn From Referrals</h2>
                <p className="word-break referal_text text-center">https://millionsmatic.com/{this.state.YourAddress}</p>
              </div>
              <div className="text-center zindex">
                <CopyToClipboard text={`https://millionsmatic.com/${this.state.YourAddressfull}`}
                  onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })
                  }>
                  <button className="all_btn">Copy Link</button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>    


                  

              
      </Frame>
    );
  }
}

const useStyles = {
  root: {
    flexGrow: 1
  }
}

export default withRouter(withStyles(useStyles)(withSnackbar(Home)));